.App {
  text-align: center;
  height: 100%;
  margin-bottom: 80px;
}

.App-logo {
  height: 40vmin;  
}

.App-header {  
  text-align: left;
  margin-left: 10px;
  align-items: center;
}

.App-footer {  
   position:fixed;
   bottom:0;
   width:100%;
   height:60px;   /* Height of the footer */
   /*background:rgba(237, 240, 241, 0.93);*/
   background-color: white;   
}

.App-link {
  /* color: #61dafb; */
  text-decoration: none;
  color: #000;  
}

